import { API_DOMAIN, APP_CONFIG } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { zInternationalization } from 'lib/zodHelpers';

import { z } from 'zod';

export const zAppConfig = z.object({
	_id: z.optional(z.string()),
	privacy: zInternationalization,
	conditions: zInternationalization,
	policy: zInternationalization,
	deletion: zInternationalization,
	map: z.optional(z.string()),
	muteHelpPhone: z.optional(z.string()),
	walletHelpPhone: z.optional(z.string()),
	onBoardingUrl: z.optional(z.string()),
	refundUrl: z.optional(z.string()),
	iOSVersion: z.optional(z.string()),
	iOSUpdateRequired: z.optional(z.boolean()),
	androidVersion: z.optional(z.string()),
	androidUpdateRequired: z.optional(z.boolean()),
});

export async function fetchAppConfig(): Promise<AppConfig> {
	try {
		const response = await api.get(`${API_DOMAIN}${APP_CONFIG}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zAppConfig.parse(response.data);
		return data;
	} catch (error) {
		console.error(error);
		return { conditions: { es: '' }, policy: { es: '' }, privacy: { es: '' }, deletion: { es: '' } };
	}
}

export async function updateAppConfig(config: AppConfig): Promise<AppConfig> {
	try {
		const url = `${API_DOMAIN}${APP_CONFIG}`;

		if (!config._id) {
			delete config._id;
		}

		const response = await api.patch(url, config);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zAppConfig.safeParse(response.data);

		if (data.success) {
			return data.data;
		}

		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
	}

	return config;
}

export async function updateImage(image: File): Promise<AppConfig | null> {
	try {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API_DOMAIN}${APP_CONFIG}/image`;
		const response = await api.post<Artist>(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zAppConfig.safeParse(response.data);

		if (data.success) {
			return data.data;
		}
	} catch (error) {
		console.error(error);
	}
	return null;
}
