import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	open: string | null;
	access: Access | undefined;
	onSave: (params: { code?: string; plate?: string }) => void;
	handleClose: () => void;
	isEntry: boolean;
	isOut?: 'date' | 'hour';
};

function SuccessfulScan({ open, access, handleClose, onSave, isEntry, isOut }: Props) {
	const { t } = useTranslation();

	const [chosenPlate, setChosenPlate] = useState('');
	const [newPlate, setNewPlate] = useState('');

	const handleNewPlate = (plate: string) => {
		setChosenPlate('');
		setNewPlate(plate);
	};

	const handleChoosePlate = (plate: string) => {
		setNewPlate('');
		setChosenPlate((prev) => (prev === plate ? '' : plate));
	};

	const entryOrExit = isEntry ? 'ingreso' : 'egreso';

	return (
		<Dialog open={!!open}>
			<DialogTitle align="center">
				{`${capitalizeFirstLetter(entryOrExit)} QR ${typeof access?.type !== 'string' ? access?.type.description : ''}`}{' '}
				{isOut ? (
					<span style={{ color: 'red' }}>({`${isOut === 'date' ? 'Fuera de fecha' : 'Fuera de horario'}`})</span>
				) : (
					<></>
				)}
				{/* {exitOutOfDate ? <span style={{ color: 'red' }}>(Fuera de fecha)</span> : ''}
				{supplierOutOfHour ? <span style={{ color: 'red' }}>(Fuera de horario)</span> : ''} */}
			</DialogTitle>
			<DialogContent sx={{ flexDirection: 'column', display: 'flex', gap: '1rem' }}>
				<Typography fontSize={14}>
					{access?.qrType === 'multiple'
						? `Seleccioná la patente de ${entryOrExit}, ingresala si no está o continuá sin ingresarla`
						: `Podés continuar sin ingresar patente ${entryOrExit === 'ingreso' ? 'y registrarla más tarde' : ''}`}
				</Typography>
				{
					<>
						{access?.plate.map((p) => {
							return (
								<Button
									sx={{
										padding: '16px 32px',
										minHeight: '56px',
									}}
									variant={chosenPlate === p ? 'contained' : 'outlined'}
									onClick={() => handleChoosePlate(p)}
								>
									{p}
								</Button>
							);
						})}
						{access && access.qrType === 'simple' && access.plate.length > 0 ? (
							<></>
						) : (
							<TextField
								sx={{ height: '100px' }}
								disabled={false}
								label="Nueva patente"
								autoComplete="off"
								value={newPlate}
								onChange={(e) => handleNewPlate(e.target.value)}
								inputProps={{
									style: { textTransform: 'uppercase', textAlign: 'center' },
								}}
							/>
						)}
					</>
				}
			</DialogContent>
			<DialogActions sx={{ display: 'flex', flexDirection: 'column' }}>
				<DialogContentText variant="caption" sx={{ textAlign: 'center' }}>
					{access && getLastMovement(isEntry ? 'entry' : 'exit', access.movements)}
				</DialogContentText>
				<DialogContentText variant="caption" sx={{ textAlign: 'center' }}>
					{access && `${access.vehiclesInside} de ${access.slots} cocheras utilizadas`}
				</DialogContentText>

				<Box>
					<Button onClick={handleClose} color="error">
						{t('common:cancel')}
					</Button>
					<Button
						onClick={() => {
							if (access) {
								onSave({ code: access.code, plate: newPlate.toUpperCase() || chosenPlate.toUpperCase() || undefined });
								handleClose();
							}
						}}
						color="primary"
					>
						{t('common:confirm')}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
}

export default SuccessfulScan;

function parseDate(dateString: string | undefined) {
	if (!dateString) return '-';
	const date = new Date(dateString);

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	const parsedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year
		.toString()
		.slice(-2)} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
	return parsedDate;
}

function getLastMovement(type: string, movements?: Movement[]) {
	if (!movements) return '';

	const filteredMovements: Movement[] = movements.filter((movement) => movement.type === type);

	const lastMovement = filteredMovements.length > 0 ? filteredMovements.at(-1) : null;

	return lastMovement && lastMovement.plate
		? `Último ${type === 'entry' ? 'ingreso' : 'egreso'}: ${parseDate(lastMovement.date)} - ${lastMovement.plate}`
		: '';
}

function capitalizeFirstLetter(string: string) {
	if (!string) return '';
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
