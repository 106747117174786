import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import AccessTable from 'components/AccessTable/AccessTable';
import useAccess from 'hooks/useAccess';

import moment from 'moment';

import AccessFilter from 'components/AccessFilter';
import { FetchAccessProps } from 'lib/models/access';

function Access() {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams({
		page: '0',
		rowsPerPage: '25',
		text: '',
		isAssigned: '',
		from: '',
		to: '',
	});

	const [params, setParams] = useState({
		page: Number(searchParams.get('page') || 0),
		rowsPerPage: Number(searchParams.get('rowsPerPage') || 25),
		text: searchParams.get('text') || '',
		from: moment(searchParams.get('from') || moment().startOf('day')),
		to: moment(searchParams.get('to') || moment().endOf('day')),
		type: searchParams.get('type') || '',
		inPark: searchParams.get('inPark') === 'true',
	});

	const handleParams = (value: any, key: string) => {
		const pageNumber = key !== 'page' ? 0 : value;

		setParams((prev) => {
			return { ...prev, [key]: value, page: pageNumber };
		});
	};

	const searchProps: FetchAccessProps = useMemo(() => {
		const { text, type, from, to, inPark } = params;
		let searchParams = { text, type, from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD'), inPark };
		return searchParams;
	}, [params]);

	const { access, loading } = useAccess(searchProps);

	const handleChangePage = (_: unknown, newPage: number) => {
		setParams({ ...params, page: newPage });
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setParams({ ...params, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
	};

	useEffect(() => {
		const { page, rowsPerPage, text, from, to, type, inPark } = params;
		setSearchParams({
			page: `${page}`,
			rowsPerPage: `${rowsPerPage}`,
			text: `${text}`,
			from: `${from.format('YYYY-MM-DD')}`,
			to: `${to.format('YYYY-MM-DD')}`,
			type: `${type}`,
			inPark: `${inPark}`,
		});
	}, [setSearchParams, params]);

	return (
		<ScreenContainer title={t('access:access')}>
			<AccessFilter
				handleParams={handleParams}
				text={params.text}
				from={params.from}
				to={params.to}
				type={params.type}
				inPark={params.inPark}
			/>
			<AccessTable
				access={access}
				loading={loading}
				page={params.page}
				rowsPerPage={params.rowsPerPage}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</ScreenContainer>
	);
}

export default Access;
