import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoMute from '../../assets/images/mute.png';

import {
	useTheme,
	ListItemText,
	useMediaQuery,
	TableHead,
	TableRow,
	TableCell,
	Button,
	Box,
	LinearProgress,
	CircularProgress,
} from '@mui/material';

import { Face as Avatar } from '@mui/icons-material';

import ResponsiveTable from 'components/ResponsiveTable';

import { rowsPerPageOptions } from 'config/constants';

import { useNavigate } from 'react-router-dom';
import ZebraAccess from 'components/AccessTickets/ZebraAccess';
import useSponsor from 'hooks/useSponsor';

//@ts-ignore
import html2pdf from 'html2pdf.js';
import { sendAccessByEmail } from 'lib/models/access';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';

type Props = {
	loading?: boolean;
	access: Access[];
	handleChangePage: (_: unknown, newPage: number) => any;
	handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => any;
	page: number;
	rowsPerPage: number;
};

export default function AccessTable({
	loading,
	access,
	handleChangePage,
	handleChangeRowsPerPage,
	page,
	rowsPerPage,
}: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const { sponsor } = useSponsor();

	const [selected, setSelected] = useState<Access | null>(null);

	const onDetail = (selected: Access) => {
		const { code } = selected;
		navigate(`/access/${code}`);
	};
	const onSendEmail = async (acc: Access) => {
		setSelected(acc);

		setTimeout(async () => {
			let parent: ParentNode | null = null;
			let element: HTMLElement | null = null;
			let wrapper: HTMLElement | null = null;

			try {
				const accessId = acc._id;
				if (!accessId) {
					throw new Error('No se encontró el código');
				}
				element = document.getElementById(accessId);

				if (!element) {
					throw new Error('No se encontró el elemento');
				}

				wrapper = document.createElement('div');
				wrapper.style.display = 'flex';
				wrapper.style.justifyContent = 'center';
				wrapper.style.alignItems = 'center';
				wrapper.style.width = '55mm';
				wrapper.style.height = '84mm';

				parent = element.parentNode;
				if (!parent) {
					throw new Error('El elemento no tiene un nodo padre');
				}

				parent.replaceChild(wrapper, element);
				wrapper.appendChild(element);

				const options = {
					filename: 'document.pdf',
					html2canvas: { scale: 2 },
					jsPDF: {
						unit: 'mm',
						format: [85, 55],
						orientation: 'portrait',
					},
				};

				const pdfBlob = await html2pdf().from(wrapper).set(options).outputPdf('blob');

				const formData = new FormData();

				formData.append('file', pdfBlob);

				const { success } = await sendAccessByEmail(formData, acc.code);

				if (success) {
					enqueueSnackbarSuccess(`Email enviado a ${acc.email}`);
				}
			} catch (error) {
				console.error(error);
				enqueueSnackbarError('Hubo un error al enviar el mail');
			} finally {
				if (parent && element && wrapper) {
					wrapper.removeChild(element);
					parent.replaceChild(element, wrapper);
				}
				setSelected(null);
			}
		}, 500);
	};

	return (
		<Box sx={{ maxHeight: '50vh' }}>
			{!!loading && <LinearProgress />}

			<ResponsiveTable
				elements={isMobile ? access : access.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
				list={{
					primaryKey: '_id',
					avatar: <Avatar />,
					renderListItemText: (acc) => {
						return (
							<ListItemText
								id={acc._id}
								onClick={() => onDetail(acc)}
								primary={`${acc.code}`}
								secondary={
									<Box>
										<address>
											<a href={`mailto:${acc.code}`} target="_blank" rel="noreferrer">
												{acc.code}
											</a>
										</address>
									</Box>
								}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('access:code')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('access:type')}
								</TableCell>
								<TableCell align="left" padding="normal">
									Nro ID
								</TableCell>
								<TableCell align="left" padding="normal">
									Descripción
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('access:plate')}
								</TableCell>
								<TableCell align="left" padding="normal">
									Fecha desde
								</TableCell>
								<TableCell align="left" padding="normal">
									Fecha hasta
								</TableCell>

								<TableCell colSpan={3} align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (acc) => {
						// const isMultiple = acc.qrType === 'multiple';
						return (
							<TableRow tabIndex={-1} key={acc._id}>
								<TableCell>{acc.code}</TableCell>
								<TableCell>{typeof acc.type !== 'string' ? acc.type.description : '-'}</TableCell>
								<TableCell>{acc.tent || '-'}</TableCell>
								<TableCell>{acc.description || '-'}</TableCell>
								<TableCell>
									{/* {acc.plate.length > 0 ? acc.plate.map((p) => <>{p}</>) : '-'} */}
									{acc.plate.join(' | ') || '-'}
								</TableCell>
								<TableCell>{parseDate(acc.validDate.from)}</TableCell>
								<TableCell>{parseDate(acc.validDate.to)}</TableCell>
								<TableCell colSpan={3} sx={{ display: 'flex', alignItems: 'center' }}>
									<Box>
										<Button sx={{ marginRight: 1 }} onClick={() => onDetail(acc)} variant="outlined">
											Detalle
										</Button>
									</Box>
									{acc.email && (
										<Box>
											<Button onClick={() => onSendEmail(acc)} variant="outlined">
												{selected ? <CircularProgress /> : 'Enviar mail'}
											</Button>
										</Box>
									)}
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: access.length,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>

			{selected && (
				<div style={{ display: 'none', position: 'absolute', right: -10000, top: -10000 }}>
					<ZebraAccess
						access={selected}
						logoMute={logoMute}
						sponsor={sponsor}
						seeToggleButton={false}
						onChangeStatus={() => {}}
					/>
				</div>
			)}
		</Box>
	);
}

function parseDate(dateString?: string) {
	if (dateString) {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = months[date.getMonth()];
		const year = String(date.getFullYear()).slice(-2);

		return `${day}-${month}-${year}`;
	}
	return '-';
}

const months: { [key: number]: string } = {
	0: 'ene',
	1: 'feb',
	2: 'mar',
	3: 'abr',
	4: 'may',
	5: 'jun',
	6: 'jul',
	7: 'ago',
	8: 'sep',
	9: 'oct',
	10: 'nov',
	11: 'dic',
};
