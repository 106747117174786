import { ACCESS_URL, API_DOMAIN } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import moment from 'moment';
import { z } from 'zod';
// import { zVehicle } from './vehicles';

export const zAccess = z.object({
	_id: z.string(),
	code: z.string(),
	qrType: z.string(),
	// type: z.string().or(zVehicle),
	slots: z.number(),
	plate: z.array(z.optional(z.string())),
	validDate: z.object({ from: z.optional(z.string()), to: z.optional(z.string()) }),
	tent: z.optional(z.string()),
	isActive: z.boolean(),
	email: z.optional(z.string()),
	movements: z.array(z.optional(z.object({}))),
	createdAt: z.optional(z.string()),
});

export type FetchAccessSearchProps = {
	text?: string;
	from?: string;
	to?: string;
	inPark?: boolean;
	codes?: string[];
};

export type FetchAccessProps = FetchAccessSearchProps;

export async function fetchAccess(props?: FetchAccessSearchProps): Promise<Access[]> {
	try {
		const response = await api.get<Access[]>(`${API_DOMAIN}${ACCESS_URL}`, {
			params: { filter: props },
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (!Array.isArray(response.data) || (response.data.length > 0 && !zAccess.safeParse(response.data[0]).success)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchAccessByCode(code: string): Promise<{ access: Access; outOfDate: boolean } | null> {
	try {
		const response = await api.get<{ access: Access; outOfDate: boolean }>(`${API_DOMAIN}${ACCESS_URL}/verify/${code}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function verifyCodeDateRangeOnEntry(code: string): Promise<
	| {
			access: Access;
			outOfDate: boolean;
			alreadyUsed: boolean;
			fullSlots: boolean;
			outOfHour: boolean;
			canAccessAfterHour: boolean;
	  }
	| string
> {
	try {
		const response = await api.get<{
			access: Access;
			outOfDate: boolean;
			alreadyUsed: boolean;
			fullSlots: boolean;
			outOfHour: boolean;
			canAccessAfterHour: boolean;
		}>(`${API_DOMAIN}${ACCESS_URL}/verify/${code}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		return response.data;
	} catch (error: any) {
		if (error.request.status === 403) return 'system:titlesUnauthorized';

		if (typeof error.response.data.message === 'string') {
			return error.response.data.message;
		}
		return 'Código inexistente';
	}
}

export async function verifyCodeDateRangeOnExit(
	plate: string
): Promise<{ access: Access; outOfDate: boolean; alreadyUsed: boolean; outOfHour?: boolean } | string> {
	try {
		const response = await api.get<{ access: Access; outOfDate: boolean; alreadyUsed: boolean; outOfHour?: boolean }>(
			`${API_DOMAIN}${ACCESS_URL}/verifyExit/${plate}`
		);

		return response.data;
	} catch (error: any) {
		if (error.request.status === 403) return 'system:titlesUnauthorized';

		if (typeof error.response.data.message === 'string') {
			return error.response.data.message;
		}

		return 'Código inexsistente';
	}
}

export async function saveAccess(
	startDate: string,
	endDate: string,
	quantity: number,
	type: string
): Promise<Access[]> {
	const url = `${API_DOMAIN}${ACCESS_URL}/simple`;

	const response = await api.post<Access[]>(url, { startDate, endDate, quantity, type });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	if (!Array.isArray(response.data) || (response.data.length > 0 && !zAccess.safeParse(response.data[0]).success)) {
		throw new Error('invalid response');
	}

	return response.data;
}

export async function saveAccessTent(
	startDate: string,
	endDate: string,
	type: string,
	tent: string,
	plate: string[],
	slots: number,
	email?: string,
	description?: string
): Promise<Access> {
	const url = `${API_DOMAIN}${ACCESS_URL}/multiple`;

	const response = await api.post<Access>(url, {
		startDate,
		endDate,
		type,
		tent,
		email,
		slots,
		plate,
		description,
	});

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	if (!zAccess.safeParse(response.data).success) {
		throw new Error('invalid responsewasdasd');
	}

	return response.data;
}

export async function saveEntry({ code, plate }: { code: string; plate?: string }): Promise<Access> {
	const url = `${API_DOMAIN}${ACCESS_URL}/movement/${code}/entry`;

	const response = await api.patch<Access>(url, { plate });

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data;
}

export async function saveExit({
	code,
	plate,
}: {
	code?: string;
	plate?: string;
}): Promise<{ access: Access; outOfDate: boolean }> {
	if (!code && !plate) {
		throw new Error('Missing code or plate');
	}

	const url = `${API_DOMAIN}${ACCESS_URL}/movement/${code || plate}/exit`;

	const response = await api.patch<{ access: Access; outOfDate: boolean }>(url, { plate });

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data;
}

export async function changeStatus(id: string) {
	try {
		if (!id) throw new Error('Missing ID');
		const url = `${API_DOMAIN}${ACCESS_URL}/change-status/${id}`;

		const response = await api.patch<Access>(url);

		if (!checkStatus(response)) {
			throw new Error('Invalid credentials');
		}
		return response.data;
	} catch (error: any) {
		return null;
	}
}
export async function canSavePlate(code: string): Promise<Access> {
	const url = `${API_DOMAIN}${ACCESS_URL}/can-save-plate/${code}`;

	const response = await api.get<Access>(url);

	return response.data;
}

export async function savePlate({ code, plate }: { code: string; plate: string }) {
	try {
		if (!code || !plate) throw new Error('Missing params');

		const url = `${API_DOMAIN}${ACCESS_URL}/save-plate/${code}`;

		const response = await api.patch<{ access: Access; error: string }>(url, { plate });

		return response.data;
	} catch (error: any) {
		console.error(error);
		return error.response.data.error || 'Error inesperado';
	}
}

export async function syncScans() {
	try {
		const scanQueue = JSON.parse(localStorage.getItem('scanQueue') || '[]');

		if (scanQueue.length === 0) return [];

		const url = `${API_DOMAIN}${ACCESS_URL}/sync`;

		const response = await api.post(url, { scanQueue });

		if (!checkStatus(response)) {
			throw new Error('Invalid credentials');
		}
		localStorage.setItem('scanQueue', '[]');
		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function fetchDailyCodes(): Promise<Access[] | null> {
	try {
		const response = await api.get<Access[]>(`${API_DOMAIN}${ACCESS_URL}`, {
			params: { date: moment().startOf('day').toISOString(), isAssigned: 'any' },
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (!Array.isArray(response.data)) {
			throw new Error('invalid response');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function syncParking(): Promise<{ success: boolean }> {
	try {
		const response = await api.post<{ success: boolean }>(`${API_DOMAIN}${ACCESS_URL}/sync`);

		return response.data;
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function sendAccessByEmail(data: FormData, code: string): Promise<{ success: boolean }> {
	try {
		const response = await api.post<{ success: boolean }>(`${API_DOMAIN}${ACCESS_URL}/send-email/${code}`, data);

		return response.data;
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}
