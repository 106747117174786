import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, FormGroup, LinearProgress, TextField, Select, MenuItem, Box } from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';

import { updateUser, deleteUser, resetPassword } from 'lib/models/users';

import useLoggedUserDocument from 'hooks/useLoggedUserDocument';
import ConfirmationDialog from 'components/ConfirmationDialog';

import useLogout from 'hooks/useLogout';
import useSelectedLanguage from 'hooks/useSelectedLanguage';

import useUpdateLanguage from './hooks/useUpdateLanguage';

import { UsersTypes } from 'types/global';
import { enqueueSnackbarError } from 'lib/helpers';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import useIsAppUsers from 'hooks/useIsAppUsers';

type Props = {
	id: string;
	editingSelf: boolean;
	withPasswordReset: boolean;
	isModal: boolean;
	withDelete: boolean;
	type?: UserType[];
	withStatus: boolean;
	initialize?: User | null;
	userLoading: boolean;
};

function UserEditionForm({
	id,
	withDelete,
	editingSelf,
	withPasswordReset,
	isModal,
	type,
	withStatus,
	initialize,
	userLoading,
}: Props) {
	const { t } = useTranslation();

	const updateLanguage = useUpdateLanguage();

	const navigate = useNavigate();

	const newUser = !!id && id === 'new';

	const selectedLanguage = useSelectedLanguage();

	const logout = useLogout();

	const successSnackbar = useSuccessSnackbar();

	const [isLoading, setLoading] = useState<boolean>(true);

	const userSession = useLoggedUserDocument();

	const loggedUserType: UserType | '' = userSession?.type || '';
	const isAdmin = loggedUserType === UsersTypes.admin;
	const isPanel = loggedUserType === UsersTypes.panel;

	const showClientSelect = editingSelf && loggedUserType === UsersTypes.user;
	const defaultType = showClientSelect ? UsersTypes.user : UsersTypes.panel;

	const [user, setUser] = useState<User>(getDefaultUser(defaultType, type));

	const [success, setSuccess] = useState<boolean>(false);
	const [confirmDeleteUser, setDeleteUser] = useState(false);
	const [confirmResetPassword, setResetPassword] = useState(false);
	const [language, setLanguage] = useState(selectedLanguage);

	const canSave = user.email && user.first_name && user.last_name;

	const loading = isLoading || userLoading;
	const userCreated = !loading && success && newUser;

	const isAppUsers = useIsAppUsers();

	const navigateTo = isAppUsers ? '/users' : '/panel/users';

	const isLoggedUser = userSession?.username === user?.username;

	useEffect(() => {
		if (!userLoading) {
			if (!newUser && initialize) {
				setUser(initialize);
			}
			setLoading(false);
		}
	}, [userLoading, initialize, newUser]);

	useEffect(() => {
		setLanguage(selectedLanguage);
	}, [selectedLanguage]);

	const onUpdate = (key: keyof User) => {
		return (event: any) => {
			const value = event.target.value;

			if (user) {
				setUser({ ...user, [key]: value });
			}
		};
	};

	const save = async () => {
		try {
			setLoading(true);
			await updateUser(user);
			setSuccess(true);
			successSnackbar();
			if (language) {
				updateLanguage(language);
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		navigate(navigateTo);
	};

	function toggleDeleteUser() {
		setDeleteUser(true);
	}

	function toggleResetPassword() {
		setResetPassword(true);
	}

	async function onDeleteConfirm(confirmed: boolean) {
		try {
			setDeleteUser(false);
			if (confirmed) {
				setLoading(true);
				await deleteUser(user);
				navigate(navigateTo);
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	}

	async function onResetConfirm(confirmed: boolean) {
		try {
			setResetPassword(false);
			if (confirmed) {
				setLoading(true);
				await resetPassword(user);
				if (!id) {
					logout();
				} else {
					navigate(navigateTo);
				}
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	}

	return (
		<Box>
			{isModal && (
				<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 1, justifyContent: 'flex-end' }}>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<CloseIcon />}
						onClick={() => navigate(navigateTo)}
					>
						{t('common:close')}
					</Button>
				</Box>
			)}

			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<FormGroup>
				<TextField
					id="username"
					label={t('common:username')}
					fullWidth={true}
					value={user.username}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('username')}
					disabled={id !== 'new'}
				/>

				<TextField
					id="first"
					label={t('common:firstName')}
					fullWidth={true}
					value={user.first_name}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('first_name')}
					disabled={loading}
				/>
				<TextField
					id="last"
					label={t('common:lastName')}
					fullWidth={true}
					value={user.last_name}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('last_name')}
					disabled={loading}
				/>
				<TextField
					id="email"
					label={t('common:email')}
					fullWidth={true}
					value={user.email || ''}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('email')}
					inputMode="email"
					type="email"
					disabled={loading}
				/>
				{
					<Select
						labelId="type"
						id="type"
						label={t('common:role')}
						fullWidth={true}
						value={user.type}
						variant="outlined"
						sx={{ mb: 3 }}
						onChange={onUpdate('type')}
						disabled={loading || user.username === 'admin' || editingSelf}
					>
						{(isLoggedUser || loggedUserType === UsersTypes.admin) && (
							<MenuItem value={UsersTypes.admin}>{t('common:role_admin')}</MenuItem>
						)}
						{(isLoggedUser || loggedUserType === UsersTypes.admin || loggedUserType === UsersTypes.parkingAdmin) && (
							<MenuItem value={UsersTypes.parkingAdmin}>{t('common:role_parkingAdmin')}</MenuItem>
						)}
						{/* {(isLoggedUser || loggedUserType === UsersTypes.admin || loggedUserType === UsersTypes.parkingAdmin) && (
							<MenuItem value={UsersTypes.parkingCashier}>{t('common:role_parkingCashier')}</MenuItem>
						)} */}
						{(isLoggedUser || loggedUserType === UsersTypes.admin || loggedUserType === UsersTypes.parkingAdmin) && (
							<MenuItem value={UsersTypes.parkingGate}>{t('common:role_parkingGate')}</MenuItem>
						)}
						{(isLoggedUser || loggedUserType === UsersTypes.admin || loggedUserType === UsersTypes.panel) && (
							<MenuItem value={UsersTypes.panel}>{t('common:role_panel')}</MenuItem>
						)}
						{(isLoggedUser || loggedUserType === UsersTypes.admin || loggedUserType === UsersTypes.barAdmin) && (
							<MenuItem value={UsersTypes.barAdmin}>{t('common:role_barAdmin')}</MenuItem>
						)}
						{(isLoggedUser || loggedUserType === UsersTypes.admin || loggedUserType === UsersTypes.barAdmin) && (
							<MenuItem value={UsersTypes.barPanel}>{t('common:role_barPanel')}</MenuItem>
						)}
						{(isLoggedUser || loggedUserType === UsersTypes.admin || loggedUserType === UsersTypes.barAdmin) && (
							<MenuItem value={UsersTypes.barCashier}>{t('common:role_barCashier')}</MenuItem>
						)}
						{(isLoggedUser || showClientSelect) && <MenuItem value={UsersTypes.user}>{t('common:role_user')}</MenuItem>}
					</Select>
				}

				{isAdmin && withStatus && (
					<Select
						labelId="status"
						id="status"
						label={t('common:status')}
						fullWidth={true}
						value={user.status || 'isActive'}
						variant="outlined"
						sx={{ mb: 3 }}
						onChange={onUpdate('status')}
						disabled={loading || user.username === 'admin'}
					>
						<MenuItem value="isActive">{t('common:active')}</MenuItem>
						<MenuItem value="inactive">{t('common:inactive')}</MenuItem>
					</Select>
				)}

				{
					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={!canSave}
					>
						{t('common:save')}
					</Button>
				}
				{withDelete &&
					user.username !== 'admin' &&
					(isAdmin || (isPanel && user.type === UsersTypes.user)) &&
					!newUser &&
					!editingSelf && (
						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<DeleteIcon />}
							onClick={toggleDeleteUser}
						>
							{t('common:delete')}
						</Button>
					)}
				{withPasswordReset && !newUser && isAdmin && (
					<Button
						variant="contained"
						color="warning"
						size="large"
						sx={{ m: 1 }}
						startIcon={<DeleteIcon />}
						onClick={toggleResetPassword}
					>
						{t('common:resetPassword')}
					</Button>
				)}
			</FormGroup>

			{!userCreated && confirmDeleteUser && (
				<ConfirmationDialog
					title={t('users:deleteTitle')}
					description={t('common:deleteText') || ''}
					onClose={onDeleteConfirm}
					loading={loading}
				/>
			)}

			{!userCreated && confirmResetPassword && (
				<ConfirmationDialog
					title={t('common:resetPasswordTitle')}
					description={t('common:resetPasswordText') || ''}
					onClose={onResetConfirm}
					loading={loading}
				/>
			)}
		</Box>
	);
}

export default UserEditionForm;

function getDefaultUser(defaultType: UsersTypes, type?: UserType[]): User {
	return {
		_id: '',
		first_name: '',
		last_name: '',
		Rules: {},
		status: 'isActive',
		type: !!type ? type[0] : defaultType,
		username: '',
	};
}
