import { Box, LinearProgress } from '@mui/material';
import AccessFilter from 'components/AccessFilter';
import AccessTickets from 'components/AccessTickets';
import ScreenContainer from 'components/containers/ScreenContainer';
import useAccess from 'hooks/useAccess';
import useSponsor from 'hooks/useSponsor';
import { FetchAccessProps } from 'lib/models/access';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export type Format = '6xp' | '1xp';

function QRCodes() {
	const { t } = useTranslation();
	const { sponsor } = useSponsor();

	const [searchParams, setSearchParams] = useSearchParams({
		text: '',
		date: '',
	});

	const [params, setParams] = useState({
		page: Number(searchParams.get('page') || 0),
		rowsPerPage: Number(searchParams.get('rowsPerPage') || 25),
		text: searchParams.get('text') || '',
		from: moment(searchParams.get('from') || moment()),
		to: moment(searchParams.get('to') || moment()),
		type: searchParams.get('type') || '',
		inPark: searchParams.get('inPark') === 'true',
	});

	const handleParams = (value: any, key: string) => {
		const pageNumber = key !== 'page' ? 0 : value;

		setParams((prev) => {
			return { ...prev, [key]: value, page: pageNumber };
		});
	};

	const searchProps: FetchAccessProps = useMemo(() => {
		const { text, from, to, type, inPark } = params;
		let searchParams = { text, from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD'), type, inPark };
		return searchParams;
	}, [params]);

	const { access, refetch, loading } = useAccess(searchProps);

	const [loadingPDF, setLoadingPDF] = useState(false);

	useEffect(() => {
		const { page, rowsPerPage, text, from, to, type, inPark } = params;
		setSearchParams({
			page: `${page}`,
			rowsPerPage: `${rowsPerPage}`,
			text: `${text}`,
			from: `${from.format('YYYY-MM-DD')}`,
			to: `${to.format('YYYY-MM-DD')}`,
			type: `${type}`,
			inPark: `${inPark}`,
		});
	}, [setSearchParams, params]);

	return (
		<>
			<ScreenContainer title={t('common:QRCodes')}>
				{(!!loading || !!loadingPDF) && <LinearProgress />}

				<Box>
					<Box sx={{ paddingTop: '1rem' }}>
						<AccessFilter
							handleParams={handleParams}
							text={params.text}
							from={params.from}
							to={params.to}
							type={params.type}
							inPark={params.inPark}
							// handleFormat={(format: Format) => setFormat(format)}
							// format={format}
						/>
					</Box>
					<AccessTickets
						accessCodes={access}
						refetch={refetch}
						loading={loading}
						setLoading={setLoadingPDF}
						sponsor={sponsor}
					/>
				</Box>
			</ScreenContainer>
		</>
	);
}

export default QRCodes;
