import React from 'react';
import { Link } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { Commute as VehiclesIcon } from '@mui/icons-material';

import { MenuItem } from 'types/global';

type Props = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
	open?: boolean;
};

export default function AccessTypes({ selected, onSelect, open }: Props) {
	return (
		<ListItem
			button
			component={Link}
			to="/parking/vehicles"
			selected={selected === MenuItem.Vehicles}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.Vehicles)}
		>
			<ListItemIcon>
				<VehiclesIcon />
			</ListItemIcon>
			{open && <ListItemText primary={'Tipos de accesos'} />}
		</ListItem>
	);
}
