import { LinearProgress } from '@mui/material';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';

import VehicleTable from 'components/VehicleTable';
import useUser from 'hooks/useUser';
import useVehicles from 'hooks/useVehicles';
import { useNavigate } from 'react-router-dom';

function VehiclesScreen() {
	const { loading, vehicles } = useVehicles();
	const { user } = useUser();

	const navigate = useNavigate();

	return (
		<ScreenContainer title={'Tipos de accesos'}>
			{user?.type !== 'parkingCashier' && (
				<CreateIcon onCreate={() => navigate('/parking/vehicles/new')} disabled={loading} />
			)}

			{!!loading && <LinearProgress />}

			<VehicleTable vehicles={vehicles} />
		</ScreenContainer>
	);
}

export default VehiclesScreen;
