import React, { useState } from 'react';
import clsx from 'clsx';

import { useNavigate } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import {
	Box,
	useTheme,
	AppBar,
	Typography,
	useMediaQuery,
	IconButton,
	Toolbar as MaterialToolbar,
} from '@mui/material';

import { AccountCircle as AccountIcon, Menu as MenuIcon, ChevronLeft as GoBackIcon } from '@mui/icons-material';

import { DRAWER_WIDTH, REACT_APP_ENVIRONMENT } from 'config/constants';

import ProfileMenu from './ProfileMenu';

type ToolbarProps = {
	open: boolean;
	onOpen(): void;
	title: string;
	goBack?: boolean;
};

function Toolbar({ open, onOpen, title, goBack }: ToolbarProps) {
	const { classes } = useStyles();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const navigate = useNavigate();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const sessionMenuOpen = Boolean(anchorEl);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const showEnvironment = ['dev', 'staging'].includes(REACT_APP_ENVIRONMENT);

	return (
		<AppBar
			position={isMobile ? 'fixed' : 'fixed'}
			color="default"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open && !isMobile,
			})}
			elevation={3}
			id="test-3"
		>
			<MaterialToolbar sx={{ height: 128, alignItems: 'flex-start', pt: 1, pb: 2 }}>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={onOpen}
					edge="start"
					className={clsx(classes.menuButton, {
						[classes.hide]: open && !isMobile,
					})}
				>
					<MenuIcon />
				</IconButton>

				<Typography
					variant="h6"
					className={clsx(classes.title, {
						[classes.dev]: REACT_APP_ENVIRONMENT === 'dev',
						[classes.staging]: REACT_APP_ENVIRONMENT === 'staging',
						[classes.titleShift]: open && !isMobile,
					})}
					noWrap
				>
					{goBack ? (
						<IconButton onClick={() => navigate(-1)}>
							<GoBackIcon />
						</IconButton>
					) : null}
					{title} {showEnvironment ? `(${REACT_APP_ENVIRONMENT.toUpperCase()})` : ''}
				</Typography>

				<Box sx={{ flexGrow: 1 }} />

				<IconButton onClick={handleClick}>
					<AccountIcon />
				</IconButton>
				<ProfileMenu open={sessionMenuOpen} onClose={handleClose} />
			</MaterialToolbar>
		</AppBar>
	);
}

const useStyles = makeStyles()((theme) => ({
	appBar: {
		width: '100%',
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		textTransform: 'capitalize',
		zIndex: theme.zIndex.drawer + 1,
	},
	appBarShift: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,
		marginLeft: DRAWER_WIDTH,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	title: {
		flexGrow: 1,
		alignSelf: 'flex-end',
		paddingLeft: theme.spacing(4),
		paddingBottom: theme.spacing(2),
	},
	titleShift: {
		paddingLeft: theme.spacing(4),
	},
	hide: {
		display: 'none',
	},
	dev: {
		backgroundColor: '#2f61e0',
		color: 'white',
		padding: 16,
	},
	staging: {
		backgroundColor: '#fa7474',
		color: 'white',
		padding: 16,
	},
}));

export default Toolbar;
