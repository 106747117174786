import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { defaultSession, storedSession } from 'lib/session';

const sessionStateSlice = createSlice({
	name: 'drawerState',
	initialState: storedSession || defaultSession,
	reducers: {
		updateLanguage(state, action: PayloadAction<SupportedLanguage>) {
			if (state.profile) {
				state.profile.language = action.payload;
			} else {
				state.profile = { language: action.payload };
			}
			i18next.changeLanguage(action.payload);
		},
		updateProfile(state, action: PayloadAction<{ language: SupportedLanguage }>) {
			if (state.profile) {
				state.profile.language = action.payload.language;
			} else {
				state.profile = action.payload;
			}
		},
		login(state, action: PayloadAction<Session>) {
			state.expired = action.payload.expired;
			state.logged = action.payload.logged;
			state.profile = action.payload.profile;
			state.serverDown = action.payload.serverDown;
			state.token = action.payload.token;
			state.user = action.payload.user;
			state.ws = action.payload.ws;
		},
		tokenExpired(state) {
			state.expired = true;
			state.logged = defaultSession.logged;
			state.profile = defaultSession.profile;
			state.serverDown = defaultSession.serverDown;
			state.token = defaultSession.token;
			state.user = defaultSession.user;
			state.ws = defaultSession.ws;
		},
		logout(state) {
			state.expired = defaultSession.expired;
			state.logged = defaultSession.logged;
			state.profile = defaultSession.profile;
			state.serverDown = defaultSession.serverDown;
			state.token = defaultSession.token;
			state.user = defaultSession.user;
			state.ws = defaultSession.ws;
		},
		serverDown(state, action: PayloadAction<boolean>) {
			state.serverDown = action.payload;
		},
		setServerDown(state) {
			state.serverDown = true;
		},
		setServerUp(state) {
			state.serverDown = false;
		},
	},
});

export const { updateLanguage, updateProfile, login, tokenExpired, logout, serverDown, setServerDown, setServerUp } =
	sessionStateSlice.actions;

export default sessionStateSlice.reducer;
