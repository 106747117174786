import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme, ListItemText, useMediaQuery, TableHead, TableRow, TableCell, Button, Box } from '@mui/material';

import { Face as Avatar } from '@mui/icons-material';

import SearchToolbar from 'components/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

import { rowsPerPageOptions } from 'config/constants';
import { useNavigate } from 'react-router';
import { sanitizedInput } from 'lib/helpers';
import useUser from 'hooks/useUser';

type Props = {
	loading?: boolean;
	editUrl?: string;
	vehicles: Vehicle[];
	paper?: boolean;
	disabled?: boolean;
	refetch?: () => {};
	customAction?: CustomTooltipProps;
};

export default function VehicleTable(props: Props) {
	const { t } = useTranslation();
	const { user } = useUser();

	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [search, setSearch] = useState('');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const searchValue = sanitizedInput(search);

	const vehicles = props.vehicles.filter((vehicle) => {
		return (
			!searchValue ||
			sanitizedInput(vehicle.code).includes(searchValue) ||
			sanitizedInput(vehicle.description).includes(searchValue)
		);
	});

	function onEdit(selected: Vehicle) {
		const { _id } = selected;
		navigate(`/${props.editUrl || 'parking/vehicles'}/${_id}`);
	}

	return (
		<FullLayout paper={props.paper}>
			<SearchToolbar
				onSearch={(value) => setSearch(value)}
				live={true}
				disabled={props.disabled}
				customAction={props.customAction}
			/>

			{!props.disabled && (
				<ResponsiveTable
					elements={isMobile ? vehicles : vehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
					list={{
						primaryKey: '_id',
						avatar: <Avatar />,
						renderListItemText: (vehicle) => {
							return (
								<ListItemText
									onClick={() => onEdit(vehicle)}
									primary={`${vehicle.code || ''} ${vehicle.description || ''}`}
									secondary={
										<Box>
											<address>
												<a href={`mailto:${vehicle.code}`} target="_blank" rel="noreferrer">
													{vehicle.code}
												</a>
											</address>
										</Box>
									}
								/>
							);
						},
					}}
					table={{
						renderHead: () => (
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										{t('common:vehicle_code')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:description')}
									</TableCell>
									{/* <TableCell align="left" padding="normal">
										{t('common:priceHour')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:priceHalfStay')}
									</TableCell> */}
									<TableCell align="left" padding="normal">
										{t('common:priceStay')}
									</TableCell>

									<TableCell align="left" padding="normal">
										Horario de entrada
									</TableCell>
									<TableCell align="left" padding="normal">
										Horario de salida
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:actions')}
									</TableCell>
								</TableRow>
							</TableHead>
						),
						renderRow: (vehicle) => {
							return (
								<TableRow tabIndex={-1} key={vehicle._id}>
									<TableCell>{vehicle.code}</TableCell>
									<TableCell>{vehicle.description}</TableCell>
									{/* <TableCell>{vehicle.priceHour}</TableCell>
									<TableCell>{vehicle.priceHalfStay}</TableCell> */}
									<TableCell>{vehicle.priceStay}</TableCell>
									<TableCell>{vehicle.startHour}</TableCell>
									<TableCell>{vehicle.endHour}</TableCell>
									<TableCell>
										{user?.type !== 'parkingCashier' ? (
											<Box>
												<Button onClick={() => onEdit(vehicle)} variant="outlined">
													{t(`common:edit`)}
												</Button>
											</Box>
										) : (
											'-'
										)}
									</TableCell>
								</TableRow>
							);
						},
						pagination: props.disabled
							? null
							: {
									count: vehicles.length,
									page,
									rowsPerPage,
									rowsPerPageOptions,
									onPageChange: handleChangePage,
									onRowsPerPageChange: handleChangeRowsPerPage,
							  },
					}}
				/>
			)}
		</FullLayout>
	);
}
