import { useEffect, useMemo, useState } from 'react';

import useLoggedUserDocument from 'hooks/useLoggedUserDocument';

import { fetchVehicle } from 'lib/models/vehicles';
import useVehicleId from './useVehicleId';

type UseVehicleResponseType =
	| {
			loading: false;
			vehicle: Omit<Vehicle, '_id'> & { _id: string };
			fromParams: boolean;
			error: false;
	  }
	| {
			loading: true;
			vehicle: null;
			fromParams: boolean;
			error: false;
	  }
	| {
			loading: false;
			vehicle: null;
			fromParams: boolean;
			error: true;
	  };

/**
 * Returns the users associated with the id received in parameter or the logged user
 */
function useVehicle() {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [vehicle, setVehicle] = useState<Vehicle | null>();
	const { id: vehicleId, fromParams } = useVehicleId();
	const loggedUser = useLoggedUserDocument();

	useEffect(() => {
		async function getVehicle() {
			if (vehicleId && vehicleId !== 'new' && fromParams) {
				const response = await fetchVehicle(vehicleId);
				if (response) {
					setVehicle(response);
				} else {
					setError(true);
				}
			} else {
				setVehicle({ code: '', priceHalfStay: 0, priceHour: 0, priceStay: 0, description: '', isOutOfSchedule: false });
			}
			setLoading(false);
		}

		getVehicle();
	}, [vehicleId, loggedUser, fromParams]);

	const data: UseVehicleResponseType = useMemo(() => {
		const { _id = '' } = vehicle || {};
		return !loading && !!vehicle
			? {
					loading: false,
					error: false,
					vehicle: { ...vehicle, _id },
					fromParams,
			  }
			: error
			? { loading: false, error: true, vehicle: null, fromParams }
			: { loading: true, error: false, vehicle: null, fromParams };
	}, [vehicle, loading, fromParams, error]);

	return data;
}

export default useVehicle;
