import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import LoopIcon from '@mui/icons-material/Loop';
import useServerDown from 'hooks/useServerDown';
import { syncScans } from 'lib/models/access';
import { enqueueSnackbarSuccess } from 'lib/helpers';
import { useState } from 'react';

export default function SyncButton() {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const isDown = useServerDown();

	const scanQueue = JSON.parse(localStorage.getItem('scanQueue') || '[]');

	const [openModal, setOpenModal] = useState(false);
	const [offlineResults, setOfflineResults] = useState<{ code: string; description: string; error: boolean }[]>([]);

	const errors = offlineResults?.filter((obj) => obj.error === true);

	const syncStoredScan = async () => {
		const response = await syncScans();

		if (!response) return enqueueSnackbarSuccess(t('access:errorOnSync'));

		if (response.length === 0) return enqueueSnackbarSuccess(t('access:noEnqueuedScans'));

		setOfflineResults(response);
		setOpenModal(true);
	};

	const handleClose = () => {
		setOpenModal(false);
		setOfflineResults([]);
	};

	return (
		<>
			{!isDown && scanQueue && scanQueue.length > 0 && (
				<Button className={classes.button} variant="contained" onClick={syncStoredScan} color="primary">
					<LoopIcon />
					{t('access:sync')}
				</Button>
			)}
			{openModal && (
				<Dialog open>
					<DialogTitle textAlign="center">
						{t('access:synchronizedAccess')}: {offlineResults.length}
					</DialogTitle>
					<Typography variant="h6" textAlign="center">
						{t('access:errors')}: {errors?.length}
					</Typography>
					<DialogContent>
						{errors?.map((r, i) => (
							<DialogContentText textAlign={'center'} color={'red'} key={i}>
								{r.code} {r.description}
							</DialogContentText>
						))}
					</DialogContent>
					<DialogActions>
						<Button color="primary" onClick={handleClose}>
							{t('common:ok')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}

const useStyles = makeStyles()((theme) => ({
	button: {
		padding: 6,
		position: 'fixed',
		[theme.breakpoints.up('sm')]: {
			bottom: theme.spacing(2),
			left: theme.spacing(30),
		},
		[theme.breakpoints.down('sm')]: {
			bottom: theme.spacing(2),
			left: theme.spacing(2),
		},
		transition: theme.transitions.create(['left'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		zIndex: theme.zIndex.drawer + 2,
	},
}));
