import { API_DOMAIN, VEHICLE_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { z } from 'zod';

export const zVehicle = z.object({
	_id: z.optional(z.string()),
	code: z.string(),
	startHour: z.string(),
	endHour: z.string(),
	isOutOfSchedule: z.boolean(),
	description: z.string(),
	priceHour: z.number(),
	priceHalfStay: z.number(),
	priceStay: z.number(),
});

export async function fetchVehicles(): Promise<Vehicle[]> {
	try {
		const response = await api.get<Vehicle[]>(`${API_DOMAIN}${VEHICLE_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!Array.isArray(response.data) || (response.data.length > 0 && !zVehicle.safeParse(response.data[0]).success)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchVehicle(id: string): Promise<Vehicle | null> {
	try {
		const response = await api.get(`${API_DOMAIN}${VEHICLE_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zVehicle.safeParse(response.data);

		return data.success ? data.data : null;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateVehicle(vehicle: Vehicle): Promise<Vehicle | null> {
	if (!vehicle._id) {
		delete vehicle._id;
	}

	const url = vehicle._id ? `${API_DOMAIN}${VEHICLE_URL}/${vehicle._id}` : `${API_DOMAIN}${VEHICLE_URL}`;

	const response = await (vehicle._id ? api.patch(url, vehicle) : api.post(url, vehicle));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zVehicle.safeParse(response.data);

	if (data.success) {
		return data.data;
	}

	console.error(data.error);

	return null;
}

export async function deleteVehicle(vehicle: Vehicle): Promise<Vehicle> {
	const url = `${API_DOMAIN}${VEHICLE_URL}/${vehicle._id}`;

	const response = await api.delete<Vehicle>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || vehicle;
}
