import useDimensions from 'hooks/useDimensions';
import useServerDown from 'hooks/useServerDown';
import { useZxing } from 'react-zxing';

type Props = {
	setOpenModal: (modal: string) => void;
	modalName: string;
	onScan: (code: string, serverIsDown: boolean) => void;
	routeName: string;
};

export const QRReader = ({ setOpenModal, modalName, onScan, routeName }: Props) => {
	const isDown = useServerDown();

	const { height, width } = useDimensions();

	const { ref } = useZxing({
		onDecodeResult(result) {
			onScan(result.getText(), isDown);
		},
		constraints: {
			video: {
				aspectRatio: width / height,
				facingMode: 'environment',
				width: { ideal: 1920 },
				height: { ideal: 1080 },
			},
			audio: false,
		},
		timeBetweenDecodingAttempts: 500,
	});

	return (
		<div style={{ padding: 0, margin: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
			{/* <div
				style={{
					zIndex: 10,
					position: 'absolute',
					width: '17em',
					height: '17em',
					boxSizing: 'border-box',
					pointerEvents: 'none',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					margin: 'auto',
					boxShadow: '0px 0px 0px 2000px rgba(0,0,0,0.75)',
				}}
			></div> */}

			<video
				ref={ref}
				style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
			/>
			{/* <Button
				size="large"
				style={{
					fontSize: '22px',
					backgroundColor: 'white',
					width: '90%',
					position: 'absolute',
					bottom: '5.5rem',
					left: '0',
					right: '0',
					margin: 'auto',
					zIndex: 999,
				}}
				onClick={() => setOpenModal(modalName)}
			>
				{t('access:manually_enter')}
			</Button> */}
		</div>
	);
};

export default QRReader;
