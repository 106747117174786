import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Close as CloseIcon, Delete as DeleteIcon, Save as SaveIcon } from '@mui/icons-material';
import { Box, Button, FormControlLabel, FormGroup, LinearProgress, Switch, TextField } from '@mui/material';

import ConfirmationDialog from 'components/ConfirmationDialog';
import useLoggedUserDocument from 'hooks/useLoggedUserDocument';

import { TimePicker } from '@mui/x-date-pickers';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { enqueueSnackbarError } from 'lib/helpers';
import { deleteVehicle, updateVehicle } from 'lib/models/vehicles';
import moment, { Moment } from 'moment';
import { UsersTypes } from 'types/global';

type Props = {
	id: string;
	isModal: boolean;
	withDelete: boolean;
	type?: UserType[];
	withStatus: boolean;
	initialize?: Vehicle | null;
	vehicleLoading: boolean;
};

function VehicleEditionForm({ id, withDelete, isModal, initialize, vehicleLoading }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const newVehicle = !!id && id === 'new';

	const [isLoading, setLoading] = useState<boolean>(true);

	const [success, setSuccess] = useState<boolean>(false);

	const userSession = useLoggedUserDocument();
	const loggedUserType: UserType | '' = userSession?.type || '';

	const [vehicle, setVehicle] = useState<Vehicle>(getDefaultVehicle());

	const [startHour, setStartHour] = useState<Moment | null>();
	const [endHour, setEndHour] = useState<Moment | null>();

	const successSnackbar = useSuccessSnackbar();

	const [confirmDeleteUser, setDeleteUser] = useState(false);

	const canSave =
		vehicle.code &&
		(vehicle.priceStay || Number(vehicle.priceStay) === 0) &&
		vehicle.description &&
		!!startHour &&
		startHour.isValid() &&
		!!endHour &&
		endHour.isValid();

	const loading = isLoading || vehicleLoading;
	const vehicleTypeCreated = !loading && success && newVehicle;

	const navigateTo = loggedUserType === UsersTypes.user ? '/' : '/parking/vehicles';

	useEffect(() => {
		if (!vehicleLoading) {
			if (!vehicleTypeCreated && initialize) {
				setVehicle(initialize);
				setStartHour(moment(initialize.startHour, 'HH:mm'));
				setEndHour(moment(initialize.endHour, 'HH:mm'));
			}
			setLoading(false);
		}
	}, [vehicleLoading, initialize, vehicleTypeCreated]);

	const onUpdate = (key: keyof Vehicle) => {
		return (event: any) => {
			const value = event.target.value;

			if (vehicle) {
				setVehicle({ ...vehicle, [key]: value });
			}
		};
	};

	const save = async () => {
		try {
			setLoading(true);
			if (!startHour || !endHour) {
				throw new Error('Invalid dates');
			}

			const start = startHour.format('HH:mm');
			const end = endHour.format('HH:mm');

			await updateVehicle({ ...vehicle, endHour: end, startHour: start });

			setSuccess(true);
			successSnackbar();
			navigate(navigateTo);
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	};

	function toggleDeleteUser() {
		setDeleteUser(true);
	}

	async function onDeleteConfirm(confirmed: boolean) {
		try {
			setDeleteUser(false);
			if (confirmed) {
				setLoading(true);
				await deleteVehicle(vehicle);

				navigate(navigateTo);
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	}

	return (
		<Box>
			{isModal && (
				<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 1, justifyContent: 'flex-end' }}>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						startIcon={<CloseIcon />}
						onClick={() => navigate(navigateTo)}
					>
						{t('common:close')}
					</Button>
				</Box>
			)}

			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<FormGroup>
				<TextField
					id="code"
					label={t('common:vehicle_code')}
					fullWidth={true}
					value={vehicle.code}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('code')}
					disabled={id !== 'new'}
				/>

				<TextField
					id="description"
					label={t('common:description')}
					fullWidth={true}
					value={vehicle.description}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('description')}
					disabled={loading}
				/>

				<TextField
					id="priceHalfStay"
					label={t('common:priceStay')}
					fullWidth={true}
					value={vehicle.priceStay}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('priceStay')}
					disabled={loading}
				/>
				<Box sx={{ display: 'flex', gap: 3 }}>
					<TimePicker
						sx={{ marginBottom: '24px', width: '100%' }}
						value={startHour}
						label={t('access:from')}
						onChange={(ev) => {
							setStartHour(ev);
						}}
						ampm={false}
					/>
					<TimePicker
						sx={{ marginBottom: '24px', width: '100%' }}
						value={endHour}
						label={t('access:to')}
						onChange={(ev) => {
							setEndHour(ev);
						}}
						ampm={false}
					/>
				</Box>
				<FormControlLabel
					checked={vehicle.isOutOfSchedule}
					onChange={() => setVehicle((prev) => ({ ...prev, isOutOfSchedule: !prev.isOutOfSchedule }))}
					control={<Switch />}
					label="Mostrar advertencia y permitir ingresar aún fuera de horario"
				/>

				<Button
					variant="contained"
					color="primary"
					size="large"
					sx={{ m: 1 }}
					startIcon={<SaveIcon />}
					onClick={save}
					disabled={!canSave}
				>
					{t('common:save')}
				</Button>
				{withDelete && !newVehicle && (
					<Button
						variant="contained"
						color="secondary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<DeleteIcon />}
						onClick={toggleDeleteUser}
					>
						{t('common:delete')}
					</Button>
				)}
			</FormGroup>

			{!vehicleTypeCreated && confirmDeleteUser && (
				<ConfirmationDialog
					title={t('vehicles:deleteTitle')}
					description={t('common:deleteText') || ''}
					onClose={onDeleteConfirm}
					loading={loading}
				/>
			)}
		</Box>
	);
}

export default VehicleEditionForm;

function getDefaultVehicle(): Vehicle {
	return {
		_id: '',
		code: '',
		description: '',
		isOutOfSchedule: false,
		priceHour: 0,
		priceHalfStay: 0,
		priceStay: 0,
	};
}
