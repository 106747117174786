import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormGroup, LinearProgress, TextField, Box, Typography, Switch, FormControlLabel } from '@mui/material';
import { Save as SaveIcon, Edit, Add } from '@mui/icons-material';

import Uploader from 'components/Uploader';
import AsyncImage from 'components/AsyncImage';

import { enqueueSnackbarError } from 'lib/helpers';
import { updateAppConfig, updateImage } from 'lib/models/appConfig';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import useAppConfig from 'hooks/useAppConfig';

function AppConfigForm() {
	const { t } = useTranslation();

	const { loading: appConfigLoading, appConfig: defaultData } = useAppConfig();

	const successSnackbar = useSuccessSnackbar();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [onBoardingUrlDisabled, setOnBoardingUrlDisabled] = useState<boolean>(true);
	const [refundUrlDisabled, setRefundUrlDisabled] = useState<boolean>(true);
	const [appVersionDisabled, setAppVersionDisabled] = useState<boolean>(true);

	const [appConfig, setAppConfig] = useState<AppConfig>({
		conditions: { es: '' },
		policy: { es: '' },
		privacy: { es: '' },
		deletion: { es: '' },
		muteHelpPhone: '',
		walletHelpPhone: '',
		androidUpdateRequired: false,
		iOSUpdateRequired: false,
	});

	const [showUploader, setShowUploader] = useState(false);

	const canSave =
		!!appConfig.conditions.es ||
		!!appConfig.policy.es ||
		!!appConfig.privacy.es ||
		!!appConfig.deletion.es ||
		!!appConfig.map ||
		!!appConfig.muteHelpPhone ||
		!!appConfig.walletHelpPhone ||
		!appConfig.refundUrl;

	const loading = isLoading || appConfigLoading;

	useEffect(() => {
		if (!appConfigLoading) {
			setAppConfig(defaultData);
		}
		setLoading(false);
	}, [appConfigLoading, defaultData]);

	const onUpdate = (key: keyof AppConfig) => {
		return (event: any) => {
			if (event) {
				const value = [
					'muteHelpPhone',
					'walletHelpPhone',
					'onBoardingUrl',
					'refundUrl',
					'iOSVersion',
					'androidVersion',
				].includes(key)
					? event.target.value
					: { es: event.target.value };
				setAppConfig((current) => ({ ...current, [key]: value }));
			}
		};
	};

	const updateSwitch = (key: 'iOSUpdateRequired' | 'androidUpdateRequired') => {
		return () => {
			setAppConfig((current) => ({ ...current, [key]: !current[key] }));
		};
	};

	async function save() {
		try {
			setLoading(true);
			const data = await updateAppConfig({ ...appConfig });
			setAppConfig(data);
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	}

	async function uploadImage(file: File): Promise<boolean> {
		const updatedAppConfig = await updateImage(file);
		if (updatedAppConfig) {
			setAppConfig(updatedAppConfig);
			return true;
		}
		return false;
	}

	function onUploaderClose(_uploaded: boolean) {
		setShowUploader(false);
	}

	return (
		<Box>
			{showUploader && (
				<Uploader id="artist-image" name="artist-file" upload={uploadImage} onClose={onUploaderClose} open={true} />
			)}

			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<FormGroup>
				<TextField
					id="conditions"
					label={t('common:conditionsUrl')}
					fullWidth={true}
					value={appConfig.conditions.es}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('conditions')}
					disabled={loading}
				/>

				<TextField
					id="policy"
					label={t('common:policyUrl')}
					fullWidth={true}
					value={appConfig.policy.es}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('policy')}
					disabled={loading}
				/>

				<TextField
					id="privacy"
					label={t('common:privacyUrl')}
					fullWidth={true}
					value={appConfig.privacy.es}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('privacy')}
					disabled={loading}
				/>

				<TextField
					id="deletion"
					label={t('common:deletionUrl')}
					fullWidth={true}
					value={appConfig.deletion.es}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('deletion')}
					disabled={loading}
				/>

				<TextField
					id="muteHelpPhone"
					label={t('common:muteHelpPhone')}
					fullWidth={true}
					value={appConfig.muteHelpPhone}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('muteHelpPhone')}
					disabled={loading}
				/>

				<TextField
					id="walletHelpPhone"
					label={t('common:walletHelpPhone')}
					fullWidth={true}
					value={appConfig.walletHelpPhone}
					variant="outlined"
					sx={{ mb: 3 }}
					onChange={onUpdate('walletHelpPhone')}
					disabled={loading}
				/>

				{!onBoardingUrlDisabled && (
					<>
						<Typography variant="caption" color="error" sx={{ mb: 4, fontSize: 13, fontWeight: 'bold' }}>
							{t('common:onBoardingUrlHelp')}
						</Typography>
						<TextField
							id="onBoardingUrl"
							label={t('common:onBoardingUrl')}
							fullWidth={true}
							value={appConfig.onBoardingUrl}
							variant="outlined"
							sx={{ mb: 2 }}
							onChange={onUpdate('onBoardingUrl')}
							disabled={loading}
						/>
					</>
				)}

				{onBoardingUrlDisabled && (
					<Button
						onClick={() => setOnBoardingUrlDisabled(!onBoardingUrlDisabled)}
						variant="contained"
						color="error"
						sx={{ width: 'fit-content' }}
					>
						{t('common:updateOnBoardingUrl')}
					</Button>
				)}

				{!refundUrlDisabled && (
					<>
						<Typography variant="caption" color="error" sx={{ mt: 4, mb: 4, fontSize: 13, fontWeight: 'bold' }}>
							{t('common:refundUrlHelp')}
						</Typography>
						<TextField
							id="refundUrl"
							label={t('common:refundUrl')}
							fullWidth={true}
							value={appConfig.refundUrl}
							variant="outlined"
							sx={{ mb: 2 }}
							onChange={onUpdate('refundUrl')}
							disabled={loading}
						/>
					</>
				)}

				{refundUrlDisabled && (
					<Button
						onClick={() => setRefundUrlDisabled(!refundUrlDisabled)}
						variant="contained"
						color="error"
						sx={{ width: 'fit-content', mt: 4 }}
					>
						{t('common:updateRefundUrl')}
					</Button>
				)}

				{!appVersionDisabled && (
					<>
						<Typography variant="caption" color="error" sx={{ mt: 4, mb: 4, fontSize: 13, fontWeight: 'bold' }}>
							{t('common:appVersionHelp')}
						</Typography>
						<TextField
							id="iOSVersion"
							label="iOS"
							fullWidth={true}
							value={appConfig.iOSVersion}
							variant="outlined"
							sx={{ mb: 2 }}
							onChange={onUpdate('iOSVersion')}
							disabled={loading}
						/>
						<TextField
							id="androidVersion"
							label="Android"
							fullWidth={true}
							value={appConfig.androidVersion}
							variant="outlined"
							sx={{ mb: 2 }}
							onChange={onUpdate('androidVersion')}
							disabled={loading}
						/>
						<FormControlLabel
							control={
								<Switch
									onChange={updateSwitch('iOSUpdateRequired')}
									checked={appConfig.iOSUpdateRequired || false}
									value={appConfig.iOSUpdateRequired ? 'true' : 'false'}
								/>
							}
							label="Actualización en iOS es requerida"
						/>
						<FormControlLabel
							control={
								<Switch
									onChange={updateSwitch('androidUpdateRequired')}
									checked={appConfig.androidUpdateRequired || false}
									value={appConfig.androidUpdateRequired}
								/>
							}
							label="Actualización en Android es requerida"
						/>
					</>
				)}

				{appVersionDisabled && (
					<Button
						onClick={() => setAppVersionDisabled(!appVersionDisabled)}
						variant="contained"
						color="error"
						sx={{ width: 'fit-content', mt: 4 }}
					>
						Actualizar última versión App
					</Button>
				)}

				<Box
					onClick={() => setShowUploader(true)}
					sx={{
						mt: 2,
						mb: 1,
						width: '350px',
						borderRadius: 1,
						borderWidth: 1,
						borderStyle: 'solid',
						borderColor: 'gray',
						padding: 1,
						cursor: 'pointer',
						':hover': {
							backgroundColor: 'lightgray',
						},
						position: 'relative',
					}}
				>
					{appConfig.map ? (
						<Edit sx={{ position: 'absolute', top: 2, right: 2 }} />
					) : (
						<Add sx={{ position: 'absolute', top: 2, right: 2 }} />
					)}

					{appConfig.map ? (
						<AsyncImage id={appConfig.map} style={{ width: '100%' }} />
					) : (
						<Box
							sx={{
								width: '100%',
								height: 150,
								borderRadius: 5,
								borderWidth: 1,
								borderStyle: 'solid',
								borderColor: 'gray',
								backgroundColor: 'lightgray',
								marginTop: 2,
							}}
						/>
					)}
				</Box>

				<Button
					variant="contained"
					color="primary"
					size="large"
					sx={{ m: 1 }}
					startIcon={<SaveIcon />}
					onClick={save}
					disabled={!canSave}
				>
					{t('common:save')}
				</Button>
			</FormGroup>
		</Box>
	);
}

export default AppConfigForm;
