import { Box, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import style from './AccessTickets.module.css';

interface Props {
	access: Access;
	logoMute: string;
	sponsor: string;
}
const PrintingAccess = ({ access, logoMute, sponsor }: Props) => {
	const isMultiple = access.qrType === 'multiple';

	return (
		<div
			className="zebraTicket"
			style={{
				width: '50mm',
				height: '80mm',
				maxWidth: '50mm',
				backgroundColor: 'none',
				padding: '0 8px',
				border: '1px solid black',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignContent: 'center',
				pageBreakAfter: 'always',
			}}
		>
			{/* <Button
				className="changeAccessStatus"
				variant="contained"
				sx={{
					position: 'absolute',
					right: '0',
					left: '0',
					top: -15,
					width: '7rem',
					height: '1rem',
					marginLeft: 'auto',
					marginRight: 'auto',
					fontSize: '.8rem',
					display: 'flex',
					zIndex: 10,
				}}
				id="changeAccessStatus"
				onClick={() => onChangeStatus(access._id)}
				// onClick={() => setOnPrint((prev) => !prev)}
			>
				{access.isActive ? 'Inhabilitar' : 'Habilitar'}
			</Button> */}
			<Box
				sx={{
					width: '100%',
					height: '0.40in',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignContent: 'center',
					mb: '0.09in',
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<img
						style={{ height: '100%', width: 'auto', borderRight: '1px solid black' }}
						src={logoMute}
						alt="Logo de Mute"
					/>
					{sponsor && (
						<img
							src={sponsor}
							style={{
								marginLeft: 2,
								height: 'auto',
								width: 'auto',
								maxWidth: '.48in',
								maxHeight: '.3in',
								verticalAlign: 'center',
							}}
							alt="sponsor"
						/>
					)}
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<Typography textAlign={'end'} lineHeight={1.2} fontSize={12} variant="caption">
						{typeof access.type !== 'string' ? access.type.description : ''}
					</Typography>
					<Typography textAlign={'end'} lineHeight={1.2} fontSize={12} variant="caption">
						{isMultiple ? `ID: ${access.tent}` : ''}
					</Typography>
				</Box>
			</Box>

			<Box sx={{ width: '100%' }}>
				<div className={style.qrContainer}>
					<div
						style={{
							border: '3px solid black',
							padding: '3px',
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
						}}
					>
						<QRCode size={120} value={access.code} />
					</div>
					<Typography variant="caption" fontSize={14}>
						{access.code}
					</Typography>
				</div>
				<Typography className={style.notValidZebra} fontSize={8} variant="caption">
					Ticket no válido como factura
				</Typography>
				{access.qrType === 'multiple' ? (
					<Typography className={style.dateRangeZebra} fontSize={'0.13in'} variant="caption">
						{`${parseDate(access.validDate.from)} a ${parseDate(access.validDate.to)}`}
					</Typography>
				) : (
					<Typography className={style.dateZebra} fontSize={'0.15in'} variant="caption">
						{`${parseDate(access.validDate.from)}`}
					</Typography>
				)}
			</Box>
			<div style={{ display: 'flex', flex: 1 }}></div>
			<Box>
				<Typography fontSize={8}>
					El establecimiento no se responsabiliza por roturas, faltantes,robos o hurtos.
				</Typography>
				<Typography fontSize={8}>La seguridad de los vehículos es responsabilidad de sus titulares.</Typography>
				<Typography fontSize={8}>La casa se reserva el derecho de admisión y permanencia.</Typography>
				<Typography fontSize={8}>El estacionamiento no incluye el uso de baños.</Typography>
			</Box>
		</div>
	);
};

export default PrintingAccess;

function parseDate(dateString?: string) {
	if (dateString) {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = months[date.getMonth()];
		const year = String(date.getFullYear()).slice(-2);

		return `${day}-${month}-${year}`;
	}
	return '-';
}

const months: { [key: number]: string } = {
	0: 'ene',
	1: 'feb',
	2: 'mar',
	3: 'abr',
	4: 'may',
	5: 'jun',
	6: 'jul',
	7: 'ago',
	8: 'sep',
	9: 'oct',
	10: 'nov',
	11: 'dic',
};
