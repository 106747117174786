import { Box, CircularProgress, Dialog, DialogTitle, Typography } from '@mui/material';
type Props = {
	open: string | null;
	code?: string;
	debugCode?: string;
};

const LoadingScan = ({ open, code, debugCode }: Props) => {
	return (
		<Dialog open={!!open}>
			<DialogTitle align="center">{`Verificando QR: ${code || debugCode}`}</DialogTitle>
			<Box display="flex" flexDirection="column" alignItems="center" padding={2}>
				<CircularProgress />
				<Typography variant="body2" sx={{ marginTop: 2 }}>
					Por favor espera...
				</Typography>
			</Box>
		</Dialog>
	);
};
export default LoadingScan;
