import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PaperContainer from 'components/containers/PaperContainer';
import ScreenContainer from 'components/containers/ScreenContainer';

import AccessDetailTable from 'components/AccessDetailTable';

import useAccessDetail from 'hooks/useAccessDetail';

function AccessDetail() {
	const { t } = useTranslation();

	const { code } = useParams<{ code: string }>();

	const { access, loading, error } = useAccessDetail();

	const withDateRange = access?.qrType === 'multiple';

	const title =
		loading || error
			? t('common:loading')
			: withDateRange
			? `Código: ${access.code} | ID: ${access.tent} | ${access.description}`
			: `Código: ${access.code}`;

	return (
		<ScreenContainer title={title} goBack={true}>
			<PaperContainer>
				<AccessDetailTable code={code || ''} initialize={access} />
			</PaperContainer>
		</ScreenContainer>
	);
}

export default AccessDetail;
