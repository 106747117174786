import PrintingAccess from 'components/AccessTickets/PrintingAccess';
import useSponsor from 'hooks/useSponsor';
import { fetchAccess } from 'lib/models/access';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logoMute from '../../../assets/images/mute.png';

export const Print = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const codes = queryParams.get('codes');

	const [access, setAccess] = useState<Access[]>([]);
	const { sponsor } = useSponsor();

	useEffect(() => {
		const codesArray = codes ? codes.split(',') : [];

		const getAccess = async (codes: string[]) => {
			const fetchedAccess = await fetchAccess({ codes });
			setAccess(fetchedAccess);
		};

		if (codesArray.length > 0) {
			getAccess(codesArray);
		}
	}, [codes]);

	useEffect(() => {
		const handleAfterPrint = () => {
			window.close(); // Cierra la ventana después de la impresión
		};

		if (access.length > 0) {
			window.addEventListener('afterprint', handleAfterPrint);
			window.print();
		}

		return () => {
			window.removeEventListener('afterprint', handleAfterPrint);
		};
	}, [access]);

	return (
		<div id="print-only">
			{access.map((a) => (
				<PrintingAccess access={a} logoMute={logoMute} sponsor={sponsor} />
			))}
		</div>
	);
};
