import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Uploader from 'components/Uploader';
import useUser from 'hooks/useUser';
import useVehicles from 'hooks/useVehicles';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { syncParking } from 'lib/models/access';
import { changeSponsor } from 'lib/models/sponsor';
import { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setSponsor } from 'store/reducers/sponsor';
import useStoreDispatch from 'store/useStoreDispatch';

type Props = {
	text: string;
	from: Moment | null;
	to: Moment | null;
	type: string;
	handleParams: (value: any, key: string) => void;
	inPark: boolean;
	// handleFormat?: (format: Format) => void;
	// format?: Format;
};

function AccessFilter({ text, from, to, type, inPark, handleParams }: Props) {
	const { t } = useTranslation();
	const dispatch = useStoreDispatch();
	const { user } = useUser();
	const { vehicles } = useVehicles();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';

	const [showUploader, setShowUploader] = useState(false);

	const onCloseUploader = () => {
		setShowUploader(false);
	};

	async function uploadImage(file: File): Promise<boolean> {
		const response = await changeSponsor(file);

		if (response) {
			dispatch(setSponsor(response));
			return true;
		}

		return false;
	}

	const onSync = async () => {
		try {
			const { success } = await syncParking();

			if (!success) throw new Error('No se pudo sincronizar');
			enqueueSnackbarSuccess('Estacionamiento sincronizado');
		} catch (error: any) {
			enqueueSnackbarError(error.message);
		}
	};

	return (
		<Grid container spacing={2} columns={10} alignItems="center">
			{showUploader && (
				<Uploader id="sponsor-image" name="sponsor-file" upload={uploadImage} onClose={onCloseUploader} open={true} />
			)}
			<Grid item xs={10} sm={5} md={2}>
				<TextField
					fullWidth
					value={text}
					label={'Buscar'}
					onChange={(ev) => handleParams(ev.target.value.toUpperCase(), 'text')}
				/>
			</Grid>

			<Grid item xs={10} sm={5} md={2}>
				<FormControl fullWidth>
					<InputLabel>Tipo de acceso</InputLabel>
					<Select
						labelId="accessType"
						id="accessType"
						label="Tipo de acceso"
						value={type}
						onChange={(ev) => handleParams(ev.target.value as string, 'type')}
					>
						<MenuItem key={1} value="all">
							Todos
						</MenuItem>
						{vehicles.map((v) => {
							return (
								<MenuItem key={v._id} value={v._id}>
									{v.description}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>

			{/* {handleFormat && format && (
				<Grid item xs={10} sm={5} md={2}>
					<FormControl fullWidth>
						<InputLabel>Formato</InputLabel>
						<Select
							labelId="format"
							id="format"
							label="Formato"
							value={format}
							onChange={(ev) => handleFormat(ev.target.value as Format)}
						>
							<MenuItem value={'6xp'}>{'6 x página'}</MenuItem>
							<MenuItem value={'1xp'}>{'1 x página'}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			)} */}

			<Grid item xs={10} sm={5} md={2}>
				<DatePicker
					sx={{ width: '100%' }}
					value={from}
					label={t('common:from')}
					onChange={(v) => handleParams(v, 'from')}
					format="DD/MM/YYYY"
					disabled={inPark}
				/>
			</Grid>

			<Grid item xs={10} sm={5} md={2}>
				<DatePicker
					sx={{ width: '100%' }}
					value={to}
					minDate={from || undefined}
					label={t('common:to')}
					onChange={(v) => handleParams(v, 'to')}
					format="DD/MM/YYYY"
					disabled={inPark}
				/>
			</Grid>

			<Grid item xs={10} sm={5} md={2}>
				<FormControlLabel
					control={<Switch onChange={(e) => handleParams(!inPark, 'inPark')} checked={inPark} value={inPark} />}
					label="Qrs en Park"
				/>
			</Grid>

			{/* <Button onClick={onSearch} variant="contained">
				{t('common:search')}
			</Button> */}
			<Box sx={{ textAlign: 'center', position: 'fixed', top: 100, right: 12, zIndex: 9999 }}>
				{isAdmin && (
					<Button
						sx={{ fontSize: '0.8rem', paddingX: '0.3rem', marginRight: '.2rem' }}
						variant="contained"
						onClick={onSync}
					>
						Sincronizar
					</Button>
				)}
				{(isAdmin || user?.type === 'parkingGate') && (
					<Button
						sx={{ fontSize: '0.8rem', paddingX: '0.3rem', marginRight: '.2rem' }}
						variant="contained"
						component={Link}
						to="/scan/plate"
					>
						{t('access:assignPlate')}
					</Button>
				)}
				{isAdmin && (
					<Button
						onClick={() => {
							setShowUploader(true);
						}}
						sx={{ fontSize: '0.8rem', paddingX: '0.3rem' }}
						variant="contained"
					>
						{t('access:changeSponsor')}
					</Button>
				)}
			</Box>
		</Grid>
	);
}

export default AccessFilter;
