import React, { useEffect, useState } from 'react';
import { useTheme, ListItemText, TableCell, TableHead, TableRow, useMediaQuery, Box } from '@mui/material';

import { Face as Avatar } from '@mui/icons-material';

import ResponsiveTable from 'components/ResponsiveTable';

import { rowsPerPageOptions } from 'config/constants';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';
import { useTranslation } from 'react-i18next';

type Props = {
	customAction?: CustomTooltipProps;
	code: string;
	initialize?: Access | null;
	loading?: boolean;
	disabled?: boolean;
};

export default function AccessDetailTable(props: Props) {
	const { t } = useTranslation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [access, setAccess] = useState<Access>(getDefaultAccess());

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	useEffect(() => {
		if (props.initialize) {
			setAccess(props.initialize);
		}
	}, [props.initialize]);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<ResponsiveTable
			elements={
				isMobile
					? access?.movements.slice().reverse()
					: access.movements
							.slice()
							.reverse()
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			}
			list={{
				primaryKey: 'date',
				avatar: <Avatar />,
				renderListItemText: (acc) => {
					return (
						<ListItemText
							primary={`${acc?.date}`}
							secondary={
								<Box>
									<address>
										<a href={`mailto:${acc?.type}`} target="_blank" rel="noreferrer">
											{acc?.type}
										</a>
									</address>
								</Box>
							}
						/>
					);
				},
			}}
			table={{
				renderHead: () => (
					<TableHead>
						<TableRow>
							<TableCell align="left" padding="normal">
								Patente
							</TableCell>
							<TableCell align="left" padding="normal">
								Usuario Escaneo
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('common:date')}
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('access:type')}
							</TableCell>
						</TableRow>
					</TableHead>
				),
				renderRow: (movement) => {
					return (
						<TableRow tabIndex={-1} key={movement.date}>
							<TableCell>{movement.plate || '-'}</TableCell>
							<TableCell>
								{typeof movement.userId === 'string'
									? movement.userId
									: `${movement.userId.first_name} ${movement.userId.last_name}`}
							</TableCell>
							<TableCell>{parseDate(movement.date)}</TableCell>
							<TableCell>{t(`access:${movement.type}`)}</TableCell>
						</TableRow>
					);
				},
				pagination: props.disabled
					? null
					: {
							count: access?.movements.length || 0,
							page,
							rowsPerPage,
							rowsPerPageOptions,
							onPageChange: handleChangePage,
							onRowsPerPageChange: handleChangeRowsPerPage,
					  },
			}}
		/>
	);
}

function getDefaultAccess(): Access {
	return {
		_id: '',
		plate: [],
		code: '',
		type: '',
		qrType: 'simple',
		validDate: {},
		movements: [],
		vehiclesInside: 0,
		isActive: true,
		slots: 1,
	};
}

function parseDate(dateString: string | undefined) {
	if (!dateString) return '-';
	const date = new Date(dateString);

	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	const parsedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year} ${hours
		.toString()
		.padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
	return parsedDate;
}
